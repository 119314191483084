import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { IoShareSocialOutline } from "react-icons/io5";
import { BsGlobe, BsInstagram, BsPersonCircle } from "react-icons/bs";
import { motion } from "framer-motion";
import { useBreakpointValue, Icon, Text, Spacer, IconButton, Box, Center, VStack, HStack, Heading, Fade, useDisclosure, Button, Badge, Grid, GridItem, Avatar, Image, Divider } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import OleniumLogoAnimated from "./OleniumLogoAnimated";
import OleniumLogo from "./OleniumLogo";
import {
    FaEarthAmericas,
    FaCirclePlus,
    FaChartSimple
} from "react-icons/fa6";

import {
    AiOutlineArrowDown
} from "react-icons/ai";

import {
    IoSparkles
} from "react-icons/io5";

import {
    BiUserCircle
} from "react-icons/bi";



import image1 from "./assets/1.jpg";
import image2 from "./assets/2.jpg";
import image3 from "./assets/3.jpg";
import image4 from "./assets/4.jpg";
import image5 from "./assets/5.jpg";
import James from "./assets/James.jpg";

import Lottie from "lottie-react";
import animationData from './assets/SidekickLoading.json';


const Dashboard = () => {

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

    const buttons = [
        {
            colour: "#0085FF",
            prompts: "Explore Olenium",
            icon: BsGlobe,
            link: "https://olenium.co.uk"
        },
        {
            colour: "#0085FF",
            prompts: "Follow our Instagram",
            icon: BsInstagram,
            link: "https://www.instagram.com/olenium/"
        },
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000); // 3000 milliseconds = 3 seconds

        return () => clearTimeout(timer); // Clear timer if the component is unmounted
    }, []);

    const getScreenHeight = () => {
        return window.innerHeight - 20;
    }

    useEffect(() => {
        // fade in page
        document.body.classList.add("fadeIn");
        return () => {
            document.body.classList.remove("fadeIn");
        };
    }
        , []);






    return (
        <Box h={"100vh"} w={"100vw"} bg={"black"} color={"white"} maxW={"500px"}>
            {loading ? (
                <Center h={"90vh"} w={"100vw"}>
                    <Fade in={isOpen}>
                        <OleniumLogoAnimated />
                    </Fade>
                </Center>
            ) : (
                <Center h={"100vh"} w={"100vw"}>
                    <Box p="4" w={"100vw"} h={"100vh"} bg={"black"} color={"white"} alignItems={"center"} flexDirection={"column"} maxW={"1200px"}>


                        <Center w={"100%"} mt="2" mb={4} flexDirection={"column"}>
                            <OleniumLogo />
                        </Center>

                        <Center w={"100%"} mb="8" flexDirection={"column"}>

                            <Box maxW={"500px"} w={"100%"} bg={"black"} color={"white"} alignItems={"center"} flexDirection={"column"}>

                                <Box
                                    w={"100%"}
                                    mb="8"
                                    backdropFilter={"blur(5px)"}
                                    backdropOpacity={"0.3"}
                                    bg="#171717DD"
                                    borderRadius="15px"
                                    p="3"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    zIndex={"99"}
                                    position={'sticky'}
                                    top={"5"}
                                >
                                    <HStack spacing={4} align="left">
                                        <Avatar
                                            size="md"
                                            name="James Fergus"
                                            src={James}
                                        />
                                        <Box mt={1}>
                                            <Text fontSize="sm" fontWeight="bold">James Fergus</Text>
                                            <Text fontSize="xs" fontWeight="normal">CEO & Founder</Text>
                                        </Box>
                                    </HStack>
                                    <HStack spacing={4} align="left">
                                        <Button variant="oleniumBlue" size="xs" onClick={() => {
                                            window.open("https://www.linkedin.com/in/james-fergus/")
                                        }}
                                        >
                                            LinkedIn
                                        </Button>
                                        <Button variant="oleniumBlue" size="xs" onClick={() => {
                                            window.open("mailto:james@olenium.co.uk")
                                        }}
                                        >
                                            Email
                                        </Button>
                                    </HStack>

                                </Box>

                                <Box
                                    height={'70vh'}
                                    width="100%"
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    display={"flex"}
                                >
                                    <Box
                                        position={"absolute"}
                                        height="90vh"
                                        width="100%"
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        display={"flex"}
                                        opacity={"0.2"}
                                        zIndex={"0"}
                                    >

                                        <Lottie
                                            animationData={animationData}
                                            loop
                                            autoplay
                                            style={{ height: "150%", width: "150%" }}
                                        />
                                    </Box>
                                    <VStack spacing={4} align="center" zIndex={"1"}>
                                        <motion.div
                                            initial={{ opacity: 0, y: 30 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 1, delay: 0.8 }}
                                        >
                                            <Heading
                                                fontSize={{ base: "5xl", md: "6xl" }}
                                                fontWeight="bold"
                                                textAlign={"center"}
                                                zIndex={"1"}
                                                color="white"
                                                mt={"16"}
                                            >
                                                Redefine your business with a bepoke website.
                                            </Heading>
                                        </motion.div>

                                        <motion.div
                                            initial={{ opacity: 0, y: 30 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 1, delay: 1 }}
                                        >
                                            <IconButton
                                                aria-label="Scroll down"
                                                icon={<AiOutlineArrowDown />}
                                                size="lg"
                                                variant="ghost"
                                                color="white"
                                                bottom={"0"}
                                                onClick={() => {
                                                    window.scrollTo({
                                                        top: getScreenHeight(),
                                                        behavior: 'smooth'
                                                    });
                                                }}
                                            >
                                            </IconButton>
                                        </motion.div>

                                    </VStack>

                                </Box>




                                <Badge
                                    size="xs"
                                    borderRadius="full"
                                    variant="outline"
                                    mb="8"
                                    textTransform={"capitalize"}
                                    color={"white"}
                                    px={2}
                                    py={1}
                                    mt={"52"}
                                >
                                    Our Mission
                                </Badge>

                                <Heading
                                    fontSize="4xl"
                                    fontWeight="bold"
                                    mb={"8"}
                                    lineHeight={"1.2"}
                                >
                                    Redefining brands through creative web solutions
                                </Heading>








                                <Heading
                                    fontSize="lg"
                                    fontWeight="bold"
                                >
                                    At Olenium, we specialize in crafting bespoke websites tailored to meet the unique needs of businesses in the North East United Kingdom. Our team of experienced designers and developers is dedicated to creating visually stunning and highly functional websites that help your business stand out online.
                                </Heading>

                                <Button
                                    variant="oleniumBlue"
                                    size="sm"
                                    mt={8}
                                    onClick={() => {
                                        window.open("https://calendar.app.google/SvtsyuUYwntV6Mqp7")
                                    }}
                                >
                                    Book a meeting
                                </Button>

                                <Divider my={8} />




                                <Badge
                                    size="xs"
                                    borderRadius="full"
                                    variant="outline"
                                    mb="8"
                                    textTransform={"capitalize"}
                                    color={"white"}
                                    px={2}
                                    py={1}
                                    mt={"28"}
                                >
                                    Local Expertise
                                </Badge>

                                <Heading
                                    fontSize="4xl"
                                    fontWeight="bold"
                                    mb={"8"}
                                    lineHeight={"1.2"}
                                >
                                    Why Choose Olenium?
                                </Heading>

                                <Heading
                                    fontSize="lg"
                                    fontWeight="bold"
                                >
                                    <span style={{ color: "grey" }}>Every website we build is unique, reflecting the individuality of your brand. We don't use templates, ensuring that your site is one-of-a-kind.</span>

                                    <span style={{ color: "white" }}>As a North East UK-based agency, we understand the local market and can provide insights that help your business succeed online.</span>

                                    <span style={{ color: "grey" }}>Our websites are designed with the user in mind, ensuring a seamless and enjoyable experience for your visitors.</span>
                                </Heading>

                            </Box>




                            <Heading size="xl" textAlign="center" mt="24">
                                Key aspects
                            </Heading>

                            <HStack
                                w={"100%"}
                                maxW={"500px"}
                                display="flex"
                                overflowX="auto"
                                py={5}
                                px={5}
                                css={{
                                    scrollbarWidth: 'none',
                                    '&::-webkit-scrollbar': {
                                        display: 'none'
                                    },
                                    '& > *': {
                                        flex: '0 0 auto'
                                    },
                                }}
                                spacing={5}
                            >
                                <ContentBox
                                    image={image1}
                                    icon={FaCirclePlus}
                                    title="Design & Development"
                                    description="From initial concept to final launch, we handle every aspect of website creation. Our team works closely with you to ensure your vision is brought to life with cutting-edge technology and innovative design."
                                />
                                <ContentBox
                                    image={image4}
                                    icon={FaEarthAmericas}
                                    title="E-Commerce Solutions"
                                    description="Expand your business with our robust e-commerce solutions. We build secure, user-friendly online stores that drive sales and enhance customer experience."
                                />
                                <ContentBox
                                    image={image5}
                                    icon={IoSparkles}
                                    title="Maintenance & Support"
                                    description="Keep your website running smoothly with our maintenance and support services. We offer regular updates, security checks, and performance optimizations to ensure your site is always at its best."
                                />
                                <ContentBox
                                    image={image3}
                                    icon={FaChartSimple}
                                    title="Digital Marketing"
                                    description="Boost your online presence with our digital marketing services. From SEO and PPC to social media management, we create strategies that drive traffic and convert visitors into customers."
                                />
                            </HStack>









                        </Center>

                        <Heading size="xl" textAlign="center" mt="24" mb={"8"}>
                            More to explore
                        </Heading>

                        <Center w={"100%"} mb="8" flexDirection={"column"}>

                            {buttons.map((prompt, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 30 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, delay: index * 0.2 + 0.5 }}
                                >
                                    <GridItem colSpan={1}>
                                        <ButtonContainer
                                            colour={prompt.colour}
                                            prompts={prompt.prompts}
                                            icon={prompt.icon}
                                            link={prompt.link}
                                        />
                                    </GridItem>
                                </motion.div>
                            ))}

                        </Center>

                        <Box py={8}>
                            <Text fontSize="xs" textAlign="center">
                                © {new Date().getFullYear()} Olenium. All rights reserved.
                            </Text>
                        </Box>




                    </Box>
                </Center >

            )
            }
        </Box >
    );
};

const ContentBox = ({ image, icon, title, description }) => (
    <Box
        maxWidth="360px"
        height="500px"
        borderRadius="20px"
        overflow="hidden"
        boxShadow="md"
        backgroundImage={image}
        backgroundPosition="center"
        backgroundSize="cover"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        css={{
            scrollSnapAlign: 'start'
        }}
    >
        <Box
            backdropFilter={"blur(5px)"}
            w={"100%"}
            h="40%"
            bg={"blackAlpha.500"}
            p={{ base: 3, md: 5 }}
            display="flex"
            flexDirection="column"
            borderRadius="20px"
        >
            <VStack align="center" p={2} spacing={4}>
                <Icon as={icon} color={"white"} w={{ base: "20px", md: "30px" }} h={{ base: "20px", md: "30px" }} />
                <Heading size="sm" textAlign="center">
                    {title}
                </Heading>
                <Text textAlign="center" fontSize="xs">
                    {description}
                </Text>
            </VStack>
        </Box>
    </Box>
);

const ButtonContainer = ({
    colour,
    prompts,
    icon,
    link,
    isLoading,
}) => {
    const [r, g, b] = [
        parseInt(colour.slice(1, 3), 16),
        parseInt(colour.slice(3, 5), 16),
        parseInt(colour.slice(5, 7), 16),
    ];

    // Set the alpha value for transparency (0.0 to 1.0)
    const alpha = 0.2;

    // Create the rgba color string
    const rgbaColour = `rgba(${r}, ${g}, ${b}, ${alpha})`;

    return (
        <Box
            w="350px"
            p="4"
            borderWidth={"1px"}
            borderColor={"oleniumBlack.300"}
            borderRadius="20px"
            boxShadow={"base"}
            isDisabled={isLoading}
            maxW="500px"
            my={2}

        >
            <HStack spacing={4} align="center" cursor="pointer">
                <Box
                    w={{ base: "40px", md: "50px" }}
                    h={{ base: "40px", md: "50px" }}
                    borderRadius="10px"
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    bg={rgbaColour}
                    aspectRatio={1}
                >
                    <Icon as={icon} color={colour} w={{ base: "20px", md: "30px" }} h={{ base: "20px", md: "30px" }} />
                </Box>
                <Text
                    fontSize={useBreakpointValue({ base: "xs", md: "md" })}
                    fontWeight="bold"
                >
                    {prompts}
                </Text>
                <Spacer />
                <IconButton
                    icon={<BsArrowRight />}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    onClick={() => {
                        window.open(link);
                    }
                    }
                />
            </HStack>
        </Box>
    );
};


export default Dashboard;
